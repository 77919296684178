/* eslint-disable import/order */

import { Button } from '#app/components/ui/button.tsx'
import { Icon } from '#app/components/ui/icon.tsx'
import { Link } from '@remix-run/react'

export function FooterSignup() {
	return (
		<section className="container space-y-2 bg-zinc-100 pb-16 pt-12 text-center md:space-y-4 2xl:pb-16 2xl:pt-28 3xl:pb-20 3xl:pt-40">
			<div className="relative inline-block">
				<Icon
					name="bars"
					className="absolute -left-6 -top-3 h-auto !w-12 -translate-y-1/4 text-primary-dark opacity-30 md:-top-3 md:left-0 md:!w-10 md:-translate-x-1/2 lg:-top-3 lg:!w-12 xl:top-0 2xl:!w-24 3xl:!w-40"
				/>
				<p className="relative font-display text-2xl md:text-3xl lg:text-3xl 2xl:text-4xl 3xl:text-5xl">
					Hear from us when we <br className="md:hidden" /> officially launch!
				</p>
			</div>
			<p className="mx-auto max-w-60 font-display text-sm font-semibold text-primary-dark/80 md:max-w-none md:text-base md:leading-none lg:text-xl 3xl:text-2xl">
				connections are right around the corner ~ autumn 2024
			</p>
			<Button asChild variant="cta">
				<Link
					to="/mailing-list"
					className="text-sm lg:text-base 2xl:text-xl 3xl:text-3xl"
				>
					join the mail list
				</Link>
			</Button>
			<p className="text-sm font-light lg:text-base 2xl:text-xl 3xl:text-2xl">
				( don't worry, we don't believe in spam )
			</p>
		</section>
	)
}
